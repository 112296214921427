<template>
  <div class="wrap">
    <navbar-hyatt
      :dia-index="false"
      :isPress="true"
      class="navbar"
    ></navbar-hyatt>
    <img class="titleImg" src="../assets/Renderings/Bild05.jpg" />
    <div style="background: #f5f5f7">
      <div class="press glow" id="press">
        <div class="title">
          <h2 style="font-weight: 100">Rechtliche Informationen</h2>
        </div>
        <div className="hlwrapper borderB">
          <div className="dayContainer">
            <p style="width: 151px">Impressum</p>
            <div className="inline">
              <PressTile
                publisher="TA Campus & Tower GmbH"
                title="Schloßschmidstr. 5, c/o Acconsis<br>80639 Münchenm, Germany<br>Geschäftsführer: Arnulf Damerau<br>HRB 272535 Amtsgericht München<br>office@euroatlantic.ag"
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
            </div>
          </div>
          <div className="dayContainer">
            <p style="width: 151px"></p>
            <div className="inline">
              <PressTile
                publisher="Haftungsausschluss"
                title='Die Inhalte dieser Website wurden mit größter Sorgfalt erstellt. Wir übernehmen jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte. Diese Website und alle darin enthaltenen Informationen werden "wie besehen" und ohne Gewährleistung jeglicher Art bereitgestellt. Der Betreiber dieser Website übernimmt keinerlei Verantwortung für die Richtigkeit, Vollständigkeit oder Aktualität der Informationen auf dieser Website oder für etwaige Fehler oder Auslassungen in den Informationen.<br>Der Betreiber dieser Website lehnt ausdrücklich alle Garantien und Bedingungen jeglicher Art ab, sei es ausdrücklich oder stillschweigend, einschließlich, aber nicht beschränkt auf stillschweigende Garantien oder Bedingungen der Eignung für einen bestimmten Zweck, der Nichtverletzung von Rechten Dritter oder der Gewährleistung für fehlerfreie Daten oder ununterbrochene Nutzung.<br>Der Betreiber dieser Website übernimmt keinerlei Haftung für Schäden jeglicher Art, einschließlich, aber nicht beschränkt auf direkte, indirekte, zufällige, besondere oder Folgeschäden, die sich aus der Nutzung dieser Website oder der Unfähigkeit, diese Website zu nutzen, ergeben, selbst wenn der Betreiber dieser Website auf die Möglichkeit solcher Schäden hingewiesen wurde.<br>Die Informationen auf dieser Website können jederzeit ohne Vorankündigung geändert werden. Der Betreiber dieser Website übernimmt keine Verpflichtung zur Aktualisierung der Informationen auf dieser Website.<br>Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.'
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Hinweis zu externen Links:"
                title="Unsere Website enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
            </div>
          </div>
          <div class="dayContainer">
            <p style="width: 151px">Allgemeine Hinweise</p>
            <div class="inline">
              <PressTile
                publisher=""
                title="Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
            </div>
          </div>
          <div class="dayContainer">
            <p style="width: 151px">Datenerfassung</p>
            <div class="inline">
              <PressTile
                publisher="Wer ist verantwortlich für die Datenerfassung auf dieser Website?"
                title="Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Wie erfassen wir Ihre Daten?"
                title="Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.<br><br>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT- Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Wofür nutzen wir Ihre Daten?"
                title="Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Welche Rechte haben Sie bezüglich Ihrer Daten?"
                title="Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.<br><br>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
            </div>
          </div>
          <div class="dayContainer">
            <p style="width: 151px">Hosting</p>
            <div class="inline">
              <PressTile
                publisher="IONOS"
                title="Anbieter ist die IONOS SE, Elgendorfer Str. 57, 56410 Montabaur (nachfolgend IONOS). Wenn Sie unsere Website besuchen, erfasst IONOS verschiedene Logfiles inklusive Ihrer IP-Adressen. Details entnehmen Sie der Datenschutzerklärung von IONOS:<br><a style='pointer-events:all'href='https://www.ionos.de/terms-gtc/terms-privacy' target='_blank'>https://www.ionos.de/terms-gtc/terms-privacy</a>.<br><br>Die Verwendung von IONOS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
            </div>
          </div>
          <div class="dayContainer">
            <p style="width: 151px">Pflichtinformationen</p>
            <div class="inline">
              <PressTile
                publisher="Datenschutz"
                title="Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.<br><br>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.<br><br>Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Hinweis zur verantwortlichen Stelle"
                title="Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br>TA Campus & Tower<br>Schloßschmidstr. 5, c/o Acconsis<br>80639 Münchenm, Germany<br>Email: office@euroatlantic.ag<br><br>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Speicherdauer"
                title="Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website"
                title="Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten"
                title="Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Widerruf Ihrer Einwilligung zur Datenverarbeitung"
                title="Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)"
                title="Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).<br><br>Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO)."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Beschwerderecht bei der zuständigen Aufsichtsbehörde"
                title="Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Recht auf Datenübertragbarkeit"
                title="Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Auskunft, Berichtigung und Löschung"
                title="Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
              <PressTile
                publisher="Recht auf Einschränkung der Verarbeitung"
                title="Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:<br>1) Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br>2)Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.<br>3) Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br>4) Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br><br>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
            </div>
          </div>
          <div class="dayContainer">
            <p style="width: 151px">Plugins und Tools</p>
            <div class="inline">
              <PressTile
                publisher="Vimeo ohne Tracking (Do-Not-Track)"
                title="Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.<br><br>Wenn Sie eine unserer mit Vimeo-Videos ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Wir haben Vimeo jedoch so eingestellt, dass Vimeo Ihre Nutzeraktivitäten nicht nachverfolgen und keine Cookies setzen wird.<br><br>Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.<br><br>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission sowie nach Aussage von Vimeo auf „berechtigte Geschäftsinteressen“ gestützt. Details finden Sie hier:<br><a style='pointer-events:all'href='https://vimeo.com/privacy' target='_blank'>https://vimeo.com/privacy</a>.<br><br>Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Vimeo unter:<br><a style='pointer-events:all'href='https://vimeo.com/privacy' target='_blank'>https://vimeo.com/privacy</a>."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
            </div>
          </div>
          <div class="dayContainer">
            <p style="width: 151px">Quelle</p>
            <div class="inline">
              <PressTile
                publisher=""
                title="<a style='pointer-events:all' href='https://www.e-recht24.de' target='_blank'>https://www.e-recht24.de</a>"
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
            </div>
          </div>
          <div class="dayContainer">
            <p style="width: 151px">Copyright</p>
            <div class="inline">
              <PressTile
                publisher="© 2023 EuroAtlantic LLC. Alle Rechte vorbehalten."
                title="Die auf dieser Website bereitgestellten Inhalte sind urheberrechtlich geschützt und dürfen ohne ausdrückliche Genehmigung des Urhebers nicht kopiert, vervielfältigt oder anderweitig verwendet werden.<br>Die Nutzung von Bildern, Grafiken oder anderen Inhalten auf dieser Website ist nur mit ausdrücklicher schriftlicher Genehmigung des Urhebers gestattet.<br>Alle Marken, Logos und eingetragenen Marken, die auf dieser Website verwendet werden, sind Eigentum ihrer jeweiligen Inhaber.<br>Bei Verstößen gegen das Urheberrecht behält sich der Urheber das Recht vor, rechtliche Schritte gegen den Verletzer einzuleiten."
                flag=""
                isPdf="f"
                link=""
              ></PressTile>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer class="footer"></Footer>
</template>
<script setup>
import PressTile from "../components/pressComponent2.vue";
import navbarHyatt from "../components/navbarHyatt.vue";
import Footer from "../components/Footer.vue";
import { onMounted } from "vue";
onMounted(() => {
  document.body.style.background = "#f5f5f7";
});
</script>

<style scoped>
.wrap{
  margin-bottom: 12em
}
.hlwrapper {
  pointer-events: none;
}
.titleImg {
  position: fixed;
  width: 100vw;
  height: 70vh;
  object-fit: cover;
  object-position: center center;
  left: 0;
  z-index: -1;
}
.reviews {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.reviews > * {
  width: unset;
  flex-grow: 1;
}
.inline {
  width: 100%;
}
.press {
  max-width: 1000px;
  margin: 70vh auto 0 auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.press h1 {
  font-weight: 100;
  font-size: 1.7em;
  line-height: 1em;
  padding-top: 0.2em;
}
.title {
  text-align: left;
  position: absolute;
  transform: translate(62px, -80%);
  color: white;
  font-size: 1.5em;
}
.dayContainer {
  display: flex;
  padding-bottom: 2.5em;
  box-shadow: 0 -10px 30px -30px rgb(184, 184, 184);
}
.dayContainer > p {
  text-align: right;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  padding: 32px 0.5em 1em 0;
  margin: 0;
  height: 18px;
  z-index: 10;
}

.press {
  padding: 0 0 2em 2em;
}

.borderB {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.borderT {
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}
#review {
  transform: translateX(-1em);
}

@media only screen and (max-width: 600px) {
  .title h2 {
    padding-left: 16px;
  }
  .title {
    transform: translateY(-80%);
  }
  #review {
    transform: translateX(0);
  }
  .dayContainer {
    flex-direction: column;
  }
  .dayContainer > p {
    padding-right: 1em;
    background: rgba(245, 245, 245, 0.508);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    width: unset !important;
  }

  .press {
    padding: 0 0 2em 0;
  }
  .press > h2 {
    padding-left: 32px;
  }
}

.footer{
  width: 100%;
  position: fixed;
  z-index: -1;
  bottom: 0;
}
</style>
