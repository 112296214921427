<template>
  <navbar-hyatt
    :dia-index="diaIndex"
    :is-press="false"
    class="navbar"
  ></navbar-hyatt>
  <div class="wrapper" ref="wrapper">
    <Landing></Landing>
    <img src="../assets/Renderings/Rendering4.jpg" />
    <img class="piano" src="../assets/Renderings/Rendering7.jpg" />
    <img class="window" src="../assets/Renderings/Rendering6.jpg" />
    <div class="text">
      <a
        >"The cantilevering Glass Prism is a symbol for the entirety of this
        development. <br />The Glass Prism will rotate toward the city center
        with clear views to the nature and cityscape of Dusseldorf.
        <br />Providing a dynamic spatial experience for its occupants, the
        Glass Prism will become a catalyst for creativity and provide a space
        for relaxation. <br />This area will also be a unique place to interface
        with nature through the scattering of light and shadow. <br />Encased in
        large sheets of transparent glass, it is my hope that this place becomes
        a sanctuary for inspiration and a birthplace for new methods of
        thought."
      </a>
      <div style="align-self: flex-end; padding-right: 15vw; text-align: end">
        <div>
          <img style="position: unset" src="../assets/Fotos/Sign1.jpg" />
          <img style="position: unset" src="../assets/Fotos/Sign2.jpg" />
        </div>
        <a style="align-self: flex-end; font-weight: unset; padding: 0"
          >Tadao Ando</a
        >
      </div>
    </div>
    <!--

    <div class="video">
      <div class="wrapper">
        <iframe
          id="iframe"
          src="https://player.vimeo.com/video/628493883"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="MUCON_Standort_Teaser"
          data-ready="true"
        ></iframe>
      </div>
    </div>
    <div class="video second">
      <div class="wrapper">
        <iframe
          id="iframe"
          src="https://player.vimeo.com/video/626617323"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="MUCON_Standort_Teaser"
          data-ready="true"
        ></iframe>
      </div>
    </div>

    -->
    <img src="../assets/Renderings/Rendering8.jpg" />
  </div>
  <!--
  <div class="gradient" />
    
  <more
    id="more"
    @other="showPress"
    @open="onOpenMenu"
    :class="showOtherPress ? 'moveDown' : ''"
  ></more>
  <div class="pressWrap">
    <press @other="showPress" :show="showOtherPress"></press>
  </div>

  -->

  <div class="btn">
    <img
      src="../assets/arrow.svg"
      :class="['left', showOtherPress || menuIsOpen ? 'hide' : '']"
      @click="onLeft"
    />
    <img
      src="../assets/arrow.svg"
      :class="['right', showOtherPress || menuIsOpen ? 'hide' : '']"
      @click="onRight"
    />
  </div>
</template>
<script setup>
import Landing from "../components/Landing.vue";
import navbarHyatt from "../components/navbarHyatt.vue";
import { ref, onMounted } from "vue";

const diaIndex = ref(true);

document.body.style.overflowX = "hidden";
const cancelDia = ref(false);
const showOtherPress = ref(false);
const menuIsOpen = ref(false);
const wrapper = ref(null);
const contentSize = 5;
const showPress = (doShow) => {
  showOtherPress.value = doShow;
};
const onOpenMenu = (isShowing) => {
  menuIsOpen.value = isShowing;
};
const onLeft = () => {
  cancelDia.value = true;
  let x = Math.round(wrapper.value.scrollLeft / window.innerWidth) - 1;
  if (x >= 0) scrollTo(x);
  else scrollTo(contentSize);
};
const onRight = (e, isDia = false) => {
  if (!isDia) cancelDia.value = true;
  if (isDia && cancelDia.value) return;
  let x = Math.round(wrapper.value.scrollLeft / window.innerWidth) + 1;
  if (x <= contentSize) scrollTo(x);
  else scrollTo(0);
};
const scrollTo = (x) => {
  if (x == 0) diaIndex.value = true;
  else diaIndex.value = false;
  wrapper.value.scrollTo({
    right: 0,
    left: x * window.innerWidth,
    behavior: "smooth",
  });
};
const onDiashow = () => {
  if (!cancelDia.value) {
    setTimeout(() => {
      if (!cancelDia.value) {
        onRight(null, true);
        onDiashow();
      }
    }, 5000);
  }
};
const scrollEvent = () => {
  cancelDia.value = true;
  wrapper.value.removeEventListener("scroll", scrollEvent);
};
onMounted(() => {
  //onDiashow();
  wrapper.value.addEventListener("scroll", scrollEvent, { passive: true });
});
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  overflow-y: hidden;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wrapper > * {
  z-index: 1;
}
.wrapper::-webkit-scrollbar {
  display: none;
}
.wrapper > * {
  width: 100vw;
  height: 100vh;
  flex: 0 0 100vw;
  object-fit: cover;
  scroll-snap-align: center;
  background: black;
}
.text {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 70;
  top: 0;
  background: black;
  padding-top: 3vw;
}

.text a {
  font-family: serif;
  font-size: calc(1em + 1vw);
  padding: 0 15vw 0 15vw;
}

.text img {
  width: calc(65px + 5vw);
  padding: 0.3em;
  height: auto;
  position: unset;
}

a {
  color: white;
}

.gradient {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 0.8) 100%
  );
  /*linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.8) 100%);*/
}
#more {
  transition: all ease-out 0.3s;
  position: absolute;
}

.footer {
  position: absolute;
  font-family: "Barlow Condensed", sans-serif;
  left: 50vw;
  font-weight: 100;
  top: 95vh;
  top: 95svh;
  top: 95dvh;
  top: 0;
  transform: translateX(-50%);
}
.footer:hover {
  font-weight: 400;
}
.footer a {
  text-decoration: none;
  font-size: 1em;
}

@media only screen and (max-width: 590px) {
  .moveDown {
    transform: translateY(300%);
  }
}

h5 {
  margin: 0;
  padding: 0;
  color: white;
  font-weight: 100;
  text-align: left;
}

.hide.right {
  transform: translateX(300%) rotateZ(-90deg);
}

.hide.left {
  transform: translateX(-300%) rotateZ(90deg);
}

.left {
  transition: all 0.4s ease-in;
  position: absolute;
  top: 49vh;
  left: 2vw;
  transform: rotateZ(90deg);
}

.right {
  transition: all 0.4s ease-in;
  position: absolute;
  top: 49vh;
  right: 2vw;
  transform: rotateZ(-90deg);
}

h1 {
  font-size: 1.8em;
  font-weight: normal;
  color: white;
  text-align: center;
}

.downloads {
  position: fixed;
  display: flex;
  left: 2em;
  top: 80vh;
  z-index: 1;
  height: fit-content;
  flex-direction: column;
  gap: 7px;
  transition: all 0.3s;
}

.btn img {
  width: 21px;
  height: auto;
  z-index: 99;
  position: absolute;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  padding: 1em;
  transition: all 0.15s ease-out;
}

.btn img:hover {
  transform: translateX(10px) scaleY(0.9) rotateZ(-90deg);
}

.btn .left:hover {
  transform: translateX(-10px) scaleY(0.9) rotateZ(90deg);
}

.piano {
  object-position: 50% 80%;
}

.pressWrap {
  overflow: hidden;
  position: absolute;
  height: 100vh;
  top: 0;
  pointer-events: none;
  width: 244px;
  right: 0;
}
</style>
