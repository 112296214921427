<template>
  <div class="diashow">
    <div>
      <div class="ghost">
        <img
          src="../assets/arrow.svg"
          alt=""
          class="left arrow"
          @click="move(-1)"
        />
        <img
          src="../assets/arrow.svg"
          alt=""
          class="right arrow"
          @click="move(1)"
        />
      </div>
      <div class="pager" ref="pager">
        <div
          v-for="(img, index) in imgUrls"
          class="item"
          @click="onClick(index)"
          :key="index"
        ></div>
      </div>
      <a
        ><br /><br /><b>Arbeitswelten und Natur:</b><br />Rund 40.000 m2 nach
        DGNB-Vorgaben errichtete Büro- und Konferenzflächen setzen die Standards
        für die Arbeits-, Lebens- und Wohlfühlwelt von Morgen und Übermorgen:
        eine harmonische Vereinigung von Weltklassearchitektur und
        trendsetzender Funktionalität - eine Symbiose von High- Tech und
        Natur.</a
      >
    </div>
    <div class="wrap" ref="behind">
      <img :src="getImgUrl('Bild03.jpg')" alt="" ref="imgBehind" />
      <div class="txtWrap">
        <div class="ghost"></div>
        <div ref="txtBehind" class="txt behind" v-html="txts[1]"></div>
      </div>
    </div>
    <div class="wrap" ref="front">
      <img :src="getImgUrl('Rendering1.jpg')" alt="" ref="imgFront" />
      <div class="txtWrap">
        <div class="ghost"></div>
        <div ref="txtFront" class="txt" v-html="txts[0]"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, watch } from "vue";
const index = ref(0);
const behind = ref(null);
const imgBehind = ref(null);
const txtBehind = ref(null);
const front = ref(null);
const imgFront = ref(null);
const txtFront = ref(null);
const isAnimating = ref(false);
const pager = ref(null);
const props = defineProps({
  imgUrls: Array,
  txts: Array,
});
const getImgUrl = (src) => {
  var images = require.context("../assets/Renderings", false, /\.jpg$/);
  return images("./" + src);
};
const imgUrls = [];
props.imgUrls.forEach((e) => {
  imgUrls.push(getImgUrl(e));
});
const offsetIndex = ref(0);
const onClick = (i) => {
  if (i != index.value) move(i - index.value);
};
const move = (d) => {
  if (isAnimating.value) return;
  pager.value.children[index.value].classList.remove("isActive");
  isAnimating.value = true;
  index.value =
    (((index.value + d) % props.imgUrls.length) + props.imgUrls.length) %
    props.imgUrls.length;
  front.value.style.transition = "all 1s cubic-bezier(.5,0,.5,1)";
  imgFront.value.style.transition = "all 1s cubic-bezier(.5,0,.5,1)";
  if (Math.abs(d) > 1) {
    if (d > 1) d = 1;
    else d = -1;
  }
  imgBehind.value.onload = () => {
    front.value.style.transform = "translateX(" + d * -100 + "%)";
    imgFront.value.style.transform = "translateX(" + d * 75 + "%)";
    pager.value.children[index.value].classList.toggle("isActive");
  };
  imgBehind.value.src = imgUrls[index.value];
  txtBehind.value.innerHTML = props.txts[index.value];
  txtBehind.value.style.transition = "all 0.6s ease-out";
  txtBehind.value.style.transitionDelay = ".3s";
  txtBehind.value.style.transform = "translateY(0)";
  txtBehind.value.style.opacity = 1;
};
onMounted(() => {
  imgFront.value.addEventListener(
    "transitionend",
    () => {
      front.value.style.transition = "none";
      imgFront.value.style.transition = "none";
      txtFront.value.innerHTML = props.txts[index.value];
      txtBehind.value.style.transition = "none";
      txtBehind.value.style.transitionDelay = "0s";
      txtFront.value.scrollTop = 0;
      imgFront.value.onload = () => {
        isAnimating.value = false;
        front.value.style.transform = "translateX(0)";
        imgFront.value.style.transform = "translateX(0)";
        txtBehind.value.style.transform = "translateY(80px)";
        txtBehind.value.style.opacity = 0;
      };
      imgFront.value.src = imgUrls[index.value];
    },
    { passive: true }
  );
  pager.value.children[0].classList.add("isActive");
});
</script>

<style scoped>
.diashow {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  display: flex;
}
.wrap {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}
.wrap > img {
  width: 100%;
  aspect-ratio: 1.6;
  object-fit: cover;
}
.txtWrap {
  position: absolute;
  top: 0;
  padding: 2em 0 0 0;
  z-index: -1;
  width: 100%;
  bottom: 0;
}
.txt {
  text-align: left;
  overflow: hidden;
  white-space: pre-line;
  color: white;
  color: black;
  font-size: 1em;
  box-sizing: border-box;
  background: white;
}
.behind {
  transform: translateY(80px) translateZ(100);
  opacity: 0;
  position: relative;
  z-index: 99;

}

.pager {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 0.5em;
}
.item {
  padding: 0.5em 0.7em;
  width: 36px;
  height: 2px;
  border-radius: 3px;
  transition: all 0.2s ease;
  position: relative;
}

.item::before {
  content: "";
  display: block;
  position: absolute;
  width: 36px;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
}
.item:hover::before,
.item.isActive::before {
  background: white;
  background: black;
}

.diashow .arrow {
  position: absolute;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  padding: 1em;
  transition: all 0.15s ease-out;
  z-index: 2;
}
.arrow.left {
  left: 1em;
  transform: rotateZ(90deg);
}
.right.arrow {
  right: 1em;
  transform: rotateZ(-90deg);
}
.arrow.left:hover {
  transform: translateX(-10px) scaleY(0.9) rotateZ(90deg);
}
.arrow.right:hover {
  transform: translateX(10px) scaleY(0.9) rotateZ(-90deg);
}

.ghost {
  width: 100%;
  aspect-ratio: 1.6;
  position: relative;
}

.diashow > div > a {
  visibility: hidden;
}
</style>
