<template>
  <img class="logo" src="../assets/Logos/Tower-white.svg" alt="" />
  <img
    class="main"
    src="../assets//Renderings/Rendering6.jpg"
    alt="Aussicht aus dem Glas Prisma über Düsseldorf"
  />

  <div>
    <div class="container text-center">
      <h2>
        Please enter a password <br />
        to access this page
      </h2>

      <div class="row">
        <div class="col-md-6 offset-md-3">
          <form v-on:submit.prevent="validateBeforeSubmit">
            <div class="form-group text-left">
              <label class="custom-label control-label">Password: </label>
              <input
                class="form-control password-field"
                type="password"
                name="password"
                v-model.trim="password"
              />
            </div>
            <div class="sub_cont">
            <div class="text-danger" v-if="error">
              <p>Incorrect password.</p>
            </div>
            <button class="btn-primary" type="submit">Submit</button>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storageHelper from "storage-helper";
export default {
  data() {
    return {
      error: null,
      password: null,
    };
  },
  methods: {
    validateBeforeSubmit() {
      if (this.password === process.env.VUE_APP_PW) {
        this.error = false;
        storageHelper.setItem("user-password", this.password);
        this.$router.push("/");
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style scoped>
img.main {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  inset: 0;
  z-index: -1;
}
img.logo {
  top: 0;
  left: 0;
  width: 20vw;
  height: auto;
  position: absolute;
  padding: min(2em, 4vw) min(3em, 5vw);
  max-width: 100px;
}

.container {

  text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  text-align: left;
  margin: min(2em, 4vw) min(3em, 5vw);
  background: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  padding: .8em 2em;
  padding-bottom: 1.6em;

  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px);
  box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);

  width: 338px;
  max-width: 338px;

  & h2 {
    margin: .3em 0;
    margin-bottom: .4em;
  }
}

label {
  margin-right: .4em;
}
input,
.btn-primary {
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5000px;
  backdrop-filter: blur(5px);
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: transparent;


  padding: .5em 1em;
}

.btn-primary{
  padding: .5em 2em;
  margin-left: auto;
  width: fit-content;
  font-weight: 900;
  font-size: .9em;

}

input:hover,
.btn-primary:hover{
  background: rgba(255,255,255,0.1);
      box-shadow: 4px 4px 60px 8px rgba(0,0,0,0.2);
}

input{
  flex-grow: 1;
  font-size: 1rem;
}

.form-group,
.sub_cont{
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding-top: 1em;
  align-items: center;
}

.text-danger p{
  margin: 0;
}


@media only screen and (max-width: 600px) {
  .container{
    left: 1em;
    right: 1em;
    bottom: 1em;
    margin: auto;
    max-width: 338px;
    width: unset;
    background: rgb(170 170 170 / 62%);

    & .btn-primary{
      font-size: 1em;
    }
  }
}
</style>
