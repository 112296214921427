<template>
  <div>
    <div class="mobileMenu">
      <mobileMenu
        :isOpen="menuOpen"
        :isPress="isPress"
        @on-close="$refs.hamburger.onClick()"
      />
    </div>

    <div class="flex" :class="{ onTop: isOnTop && diaIndex, isPress: isPress }">
      <img
        class="tower"
        src="../assets/Logos/Tower-white.svg"
        onclick="window.open('/','_self')"
        style="pointer-events: all; cursor: pointer"
      />
      <div class="left">
        <img
          class="ea"
          src="../assets/Logos/Hyatt.svg"
          onclick="window.open('/','_self')"
          style="pointer-events: all; cursor: pointer"
        />
        <div class="right">
          <div class="btn">
            <a class="btn-inner" href="/press">Presse</a>
          </div>
          <div class="btn">
            <div class="btn-inner"></div>
            <a
              class="btn-inner"
              href="https://www.linkedin.com/company/tadao-ando-campus-and-tower/"
              target="_blank"
              >LinkedIn</a
            >
          </div>
          <div class="btn">
            <a
              class="btn-inner"
              href="https://www.instagram.com/andofuturestudios/"
              target="_blank"
              >Instagram</a
            >
          </div>
          <div class="mobileMenu">
            <Hamburger @onClick="onMenuClick" ref="hamburger" />
          </div>
        </div>
      </div>
      <div class="gradient" />
    </div>
  </div>
</template>

<script>
import mobileMenu from "./mobileMenu.vue";
import Hamburger from "./Hamburger.vue";
export default {
  name: "navbar",
  props: ["diaIndex", "isPress"],
  components: {
    mobileMenu,
    Hamburger,
  },
  data() {
    return {
      isOnTop: true,
      menuOpen: false,
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      if (window.scrollY == 0 && this.diaIndex) this.isOnTop = true;
      else this.isOnTop = false;
    },
    onMenuClick(a) {
      this.menuOpen = a;
    },
  },
};
</script>

<style scoped>
.ea {
  transition: all 0.3s ease-out;
  width: 8vw;
  min-width: 6em;
  max-width: 9em;
  padding-left: 0.5em;
  transform-origin: center left;
}
.tower {
  margin-left: 0.5em;
  width: 6vw;
  min-width: 4em;
  max-width: 6em;
  padding: 2vw 1vw 0 3.5vw;
  transition: all 0.3s ease-out;
  transform-origin: center right;
}

.flex {
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  z-index: 101;
  width: 100%;
  padding: 0.5em 0 1em 0;
  pointer-events: none;
  justify-content: flex-start;
}
.flex.isPress {
  position: absolute;
}

.left {
  padding: 0.9em 3.5vw 0 0;
  transform: translateY(-0.5em);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 100;
}

.right {
  align-self: center;
  justify-self: flex-end;
  display: flex;
  flex-wrap: nowrap;
}

a:link,
a:visited {
  text-decoration-line: none;
}

.gradient {
  z-index: -1;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 10em;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  /*linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.8) 100%);*/
}
.onTop > .left > .ea {
  width: 11vw;
  max-width: 18em;
}

.onTop > .tower {
  width: 9vw;
  max-width: 10em;
}

.btn {
  pointer-events: all;
  padding: 3px 7px;
  margin: 0 10px;
  color: white;
  text-decoration: none;
  position: relative;
  background: transparent;
  border-radius: 15px;
  text-align: left;
  text-indent: 2px;
  transition: color 0.1s linear 0.05s;
  font-size: 1.2em;
}
.btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  border-radius: 7px;
  background: white;
  z-index: 1;
  opacity: 0;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0.2s;
}
.btn::after {
  transition: border 0.1s linear 0.05s;
}
.btn .btn-inner {
  position: relative;
  z-index: 2;
  color: white;
}
.btn:hover .btn-inner {
  color: black;
}
.btn:hover {
  color: black !important;
  transition: color 0.1s linear 0s;
}
.btn:hover::before {
  top: 0;
  height: 100%;
  opacity: 1;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0s;
}
.btn:hover::after {
  border-color: black;
  transition: border 0.1s linear 0s;
}

.mobileMenu {
  display: none;
}

@media screen and (max-width: 520px) {
  .mobileMenu {
    display: block;
  }
  .right > .btn {
    display: none;
  }
}
</style>
