<template>
  <div>
    <navbar-hyatt
      :dia-index="false"
      :isPress="true"
      class="navbar"
    ></navbar-hyatt>
    <img class="titleImg" src="../assets/Renderings/Bild09.jpg" />
    <div style="background: #f5f5f5; z-index: 1; position: relative">
      <div class="press glow" id="press">
        <div class="title">
          <h2 style="font-weight: 100">Presse</h2>
        </div>
        <div className="hlwrapper borderB">
          <div className="dayContainer">
            <div className="inline" class="reviews">
              <PressTile
                id="review"
                publisher="Hyatt"
                title="Pressemitteilung"
                flag="🇩🇪"
                isPdf="t"
                link="https://ando-campus.com/Hyatt_Pressemitteilung.pdf"
              ></PressTile>
              <PressTile
                publisher="Hyatt"
                title="Press Release"
                flag="🇬🇧"
                isPdf="t"
                link="https://ando-campus.com/Hyatt_Press_Release.pdf"
              ></PressTile>
            </div>
          </div>
          <div className="dayContainer">
            <p>Highlights</p>
            <div className="inline">
              <PressTile
                publisher="Rheinische Post"
                title="Neues Wahrzeichen für Düsseldorf"
                flag="🇩🇪"
                isPdf="f"
                link="https://ando-campus.com/ANDO-CAMPUS_RheinischePost.pdf"
              ></PressTile>
              <PressTile
                title="Tadao Ando Campus: Dieses spektakuläre Architektur-Projekt wird Düsseldorfs neue grüne Oase"
                publisher="Architectual Digest"
                flag="FS 🇩🇪"
                isPdf="t"
                link="https://www.ad-magazin.de/artikel/tadao-ando-campus-duesseldorf"
              ></PressTile>
            </div>
          </div>
          <div className="dayContainer">
            <p>MAR 2023</p>
            <div className="inline">
              <PressTile
                publisher="Baumeister"
                title="Tadao Ando Campus und Tower in Düsseldorf"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.baumeister.de/tadao-ando-campus-duesseldorf/"
              ></PressTile>
            </div>
          </div>
          <div className="dayContainer">
            <p>JAN 2023</p>
            <div className="inline">
              <PressTile
                publisher="Vivid Magazin"
                title="An Urban Rooftop Forest"
                flag="🇩🇪"
                isPdf="t"
                link="https://ando-campus.com/VIVIDMagazin.pdf"
              ></PressTile>
              <PressTile
                publisher="Garten + Landschaft"
                title="Enzo Enea und Tadao Ando: Campus und Tower Düsseldorf"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.garten-landschaft.de/tadao-ando-campus-duesseldorf/"
              ></PressTile>
              <PressTile
                title="In der Kunst gibt es kein Müssen"
                publisher="VOGUE"
                flag="FS 🇩🇪"
                isPdf="t"
                link="https://ando-campus.com/2022-01.11VOGUE_AnnaDeilmann.pdf"
              ></PressTile>
            </div>
          </div>
          <div className="dayContainer">
            <p>DEC 2022</p>
            <div className="inline">
              <PressTile
                publisher="Bild"
                title="Das Mörsenbroicher Ei wird grün"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.bild.de/regional/duesseldorf/duesseldorf-aktuell/duesseldorf-mit-diesem-sensations-bau-wird-das-moersenbroicher-ei-gruen-82369878.bild.html"
              ></PressTile>
              <PressTile
                title="Tadao Ando Campus: Dieses spektakuläre Architektur-Projekt wird Düsseldorfs neue grüne Oase"
                publisher="Architectual Digest"
                flag="FS 🇩🇪"
                isPdf="t"
                link="https://www.ad-magazin.de/artikel/tadao-ando-campus-duesseldorf"
              ></PressTile>
            </div>
          </div>
          <div className="dayContainer">
            <p>NOV 2022</p>
            <div className="inline">
              <PressTile
                publisher="The Dorf"
                title="Ando Future Studios Ausstellungspremieren"
                flag="FS 🇩🇪"
                isPdf="f"
                link="https://thedorf.de/kultur/kunst/ando-future-studios-ausstellungspremieren/"
              ></PressTile>
              <PressTile
                publisher="Monopol"
                title="Projekträume in Köln und Düsseldorf: Auf Entdeckungstour"
                flag="FS 🇩🇪"
                isPdf="f"
                link="https://www.monopol-magazin.de/projektraeume-koeln-duesseldorf-dc-open-off-spaces"
              ></PressTile>
            </div>
          </div>
          <div className="dayContainer">
            <p>OKT 2021</p>
            <div className="inline">
              <PressTile
                publisher="LA TRIBUNE DE L'HÔTELLERIE"
                title="Hyatt Place Düsseldorf Mercedesstrasse à Tadao Ando Campus & Tower annoncé pour 2024"
                flag="🇫🇷"
                isPdf="f"
                link="https://www.latribunedelhotellerie.com/allemagne-hyatt-place-dusseldorf-mercedesstrasse-a-tadao-ando-campus-tower-annonce-pour-2024/"
              ></PressTile>
              <PressTile
                publisher="IndoZone"
                title="Hyatt Ungkapkan Rencana Pembangunan Hyatt Place Düsseldorf Mercedesstrasse"
                flag="🇮🇩"
                isPdf="f"
                link="https://www.indozone.id/travel/mnsvBo7/hyatt-ungkapkan-rencana-pembangunan-hyatt-place-d-sseldorf-mercedesstrasse"
              ></PressTile>
              <PressTile
                publisher="Daily Traveling News"
                title="Hyatt proyecta la apertura de Hyatt Place Düsseldorf"
                flag="🇪🇸"
                isPdf="f"
                link="https://www.indozone.id/travel/mnsvBo7/hyatt-ungkapkan-rencana-pembangunan-hyatt-place-d-sseldorf-mercedesstrasse"
              ></PressTile>
              <PressTile
                publisher="Eurobuild"
                title="Hyatt w kampusie Tadao Ando"
                flag="🇵🇱"
                isPdf="f"
                link="https://eurobuildcee.com/news/50117-hyatt-w-kampusie-tadao-ando"
              ></PressTile>
              <PressTile
                publisher="MarketScreener"
                title="Hyatt Hotels : and EuroAtlantic Announce Plans for Place Dusseldorf Mercedesstrasse at Tadao Ando Campus & Tower"
                flag="🇺🇸"
                isPdf="f"
                link="https://www.marketscreener.com/quote/stock/HYATT-HOTELS-CORPORATION-5685450/news/Hyatt-Hotels-and-EuroAtlantic-Announce-Plans-for-Place-Dusseldorf-Mercedesstrasse-at-Tadao-Ando-Ca-36638850/"
              ></PressTile>
              <PressTile
                publisher="Hotel Online"
                title="Hyatt and EuroAtlantic Announce Plans for Hyatt Place Düsseldorf Mercedesstrasse at Tadao Ando Campus & Tower"
                flag="🇺🇸"
                isPdf="f"
                link="https://www.hotel-online.com/press_releases/release/hyatt-and-euroatlantic-announce-plans-for-hyatt-place-dusseldorf-mercedesstrasse-at-tadao-ando-campus-tower/"
              ></PressTile>
              <PressTile
                publisher="Buisnesstraveller"
                title="Plans unveiled for Hyatt Place property in Dusseldorf"
                flag="🇺🇸"
                isPdf="f"
                link="https://www.businesstraveller.com/business-travel/2021/10/10/plans-unveiled-for-hyatt-place-property-in-dusseldorf/"
              ></PressTile>
              <PressTile
                publisher="hospitalitynet"
                title="Hyatt Place Düsseldorf Mercedesstrasse at Tadao Ando Campus & Tower"
                flag="🇺🇸"
                isPdf="f"
                link="https://www.hospitalitynet.org/announcement/41007049.html"
              ></PressTile>
              <PressTile
                publisher="Breaking travel news"
                title="Hyatt unveils plans for new Düsseldorf property"
                flag="🇺🇸"
                isPdf="f"
                link="https://www.breakingtravelnews.com/news/article/hyatt-unveils-plans-for-new-duesseldorf-property/"
              ></PressTile>
              <PressTile
                publisher="Franchising.com"
                title="Hyatt and EuroAtlantic Announce Plans for Hyatt Place Düsseldorf Mercedesstrasse at Tadao Ando Campus & Tower"
                flag="🇺🇸"
                isPdf="f"
                link="https://www.franchising.com/news/20211008_hyatt_and_euroatlantic_announce_plans_for_hyatt_place_dsseldorf_mercedesstr.html"
              ></PressTile>
              <PressTile
                publisher="Hotel Online"
                title="Hyatt and EuroAtlantic Announce Plans for Hyatt Place Düsseldorf Mercedesstrasse at Tadao Ando Campus & Tower"
                flag="🇺🇸"
                isPdf="f"
                link="https://www.hotel-online.com/press_releases/release/hyatt-and-euroatlantic-announce-plans-for-hyatt-place-dusseldorf-mercedesstrasse-at-tadao-ando-campus-tower/"
              ></PressTile>
              <PressTile
                publisher="HNR Hotel News"
                title="Hyatt Place Düsseldorf Mercedesstrasse at Tadao Ando Campus & Tower Announced for 2024"
                flag="🇺🇸"
                isPdf="f"
                link="https://www.hotelnewsresource.com/article117808.html"
              ></PressTile>
              <PressTile
                publisher="BW Hotelier"
                title="Hyatt partners with EuroAtlantic to open first Hyatt Place in Düsseldorf"
                flag="🇺🇸"
                isPdf="f"
                link="https://bwhotelier.businessworld.in/article/Hyatt-partners-with-EuroAtlantic-to-open-first-Hyatt-Place-in-D-sseldorf/08-10-2021-408006/"
              ></PressTile>
              <PressTile
                publisher="Hotelexecutive"
                title="Hyatt and Euroatlantic Announce Plans for Hyatt Place Düsseldorf Mercedesstrasse at Tadao Ando Campus & Tower"
                flag="🇺🇸"
                isPdf="f"
                link="https://www.hotelexecutive.com/newswire/68904/hyatt-and-euroatlantic-announce-plans-for-hyatt-place-duumlsseldorf-mercedesstrasse-at-tadao-ando-campus-tower"
              ></PressTile>
              <PressTile
                publisher="Trend Fool"
                title="Hyatt unveils plans for new Düsseldorf property"
                flag="🇺🇸"
                isPdf="f"
                link="https://trendfool.com/travel/hyatt-unveils-plans-for-new-dusseldorf-property/"
              ></PressTile>
              <PressTile
                publisher="TravelPrNews"
                title="Hyatt announces Hyatt Place Düsseldorf Mercedesstrasse at Tadao Ando Campus & Tower"
                flag="🇺🇸"
                isPdf="f"
                link="https://travelprnews.com/hyatt-announces-hyatt-place-dusseldorf-mercedesstrasse-at-tadao-ando-campus-tower-599966/travel-press-release/2021/10/10/"
              ></PressTile>
              <PressTile
                publisher="Rheinische Post"
                title="Neues Wahrzeichen für Düsseldorf"
                flag="🇩🇪"
                isPdf="f"
                link="https://ando-campus.com/ANDO-CAMPUS_RheinischePost.pdf"
              ></PressTile>
              <PressTile
                publisher="Westdeutsche Zeitung"
                title="Neue Bauprojekte: Düsseldorf will noch mehr in die Höhe wachsen"
                flag="🇩🇪"
                isPdf="t"
                link="https://ando-campus.com/WDZ.pdf"
              ></PressTile>
              <PressTile
                publisher="Feine Adressen"
                title="TechCampus von Tadao Ando - Das neue Wahrzeichen für Düsseldorf"
                flag="🇩🇪"
                isPdf="t"
                link="https://ando-campus.com/FeineAdressen-EditionDuesseldorf3_2022.pdf"
              ></PressTile>
              <PressTile
                publisher="Reisetopia"
                title="Hyatt gibt Pläne für neues Hyatt Place in Düsseldorf bekannt"
                flag="🇩🇪"
                isPdf="f"
                link="https://reisetopia.de/news/hyatt-place-eroeffnung-duesseldorf/"
              ></PressTile>
              <PressTile
                publisher="CUBE Magazine"
                title="Kulturelle Zwischennutzung"
                flag="FS 🇩🇪"
                isPdf="f"
                link="https://www.cube-magazin.de/magazin/news/ando-future-studios"
              ></PressTile>
              <PressTile
                publisher="Technik-Smartphone-News"
                title="Pläne für Hyatt Place in Düsseldorf enthüllt - Business Traveller"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.technik-smartphone-news.de/plaene-fuer-hyatt-place-in-duesseldorf-enthuellt-business-traveller/"
              ></PressTile>
              <PressTile
                publisher="Tageskarte"
                title='Hyatt und EuroAtlantic Group planen Hotel "Hyatt Place Düsseldorf"'
                flag="🇩🇪"
                isPdf="f"
                link="https://www.tageskarte.io/hotellerie/detail/hyatt-und-euroatlantic-group-planen-hotel-hyatt-place-duesseldorf.html"
              ></PressTile>
              <PressTile
                publisher="Hogapage"
                title="Zweites Hyatt Place Hotel für Deutschland"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.hogapage.de/nachrichten/wirtschaft/hotellerie/zweites-hyatt-place-hotel-fuer-deutschland/"
              ></PressTile>
              <PressTile
                publisher="ahgz"
                title="Pläne für zweites Hyatt Place in Deutschland"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.ahgz.de/hotellerie/news/hotelprojekt-plaene-fuer-zweites-hyatt-place-in-deutschland-303516?crefresh=1"
              ></PressTile>
              <PressTile
                publisher="Hotelier.de"
                title="Hyatt Place Düsseldorf Mercedesstraße mit Franchise via EuroAtlantic"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.hotelier.de/hotellerie/hotelgewerbe/hyatt-place-duesseldorf-mercedesstrasse-mit-franchise-via-euroatlantic"
              ></PressTile>
              <PressTile
                publisher="Enea"
                title="Er soll dem projektierten Ando Campus in Düsseldorf Atem einhauchen. Wenn das jemand schafft, dann der Landschaftsplaner Enzo Enea."
                flag="🇨🇭"
                isPdf="t"
                link="https://enea.ch/wp-content/uploads/2022/10/ad1022_enea_de-1.pdf"
              ></PressTile>
              <PressTile
                publisher="Reisetopia"
                title="Hyatt gibt Pläne für neues Hyatt Place in Düsseldorf bekannt"
                flag="🇨🇭"
                isPdf="f"
                link="https://reisetopia.ch/news/hyatt-place-eroeffnung-duesseldorf/"
              ></PressTile>
              <PressTile
                publisher="Thomas Daily"
                title="Düsseldorf: Hyatt plant 300-Zimmer-Hotel im Tadao Ando Campus"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.thomas-daily.de/td-morning-news/1451187-duesseldorf-hyatt-plant-300-zimmer-hotel-im-tadao-ando-campus/"
              ></PressTile>
              <PressTile
                publisher="Property Magazine"
                title="Neues Hyatt Place Hotel im Düsseldorfer Tadao Ando Campus geplant"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.property-magazine.de/neues-hyatt-place-hotel-im-duesseldorfer-tadao-ando-campus-geplant-113576.html"
              ></PressTile>
              <PressTile
                publisher="Hotelbau"
                title="Hyatt Place zieht in den Tadao Ando Campus"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.hotelbau.de/aktuelles/hyatt-place-tadao-ando-campus/"
              ></PressTile>
              <PressTile
                publisher="Business Travel"
                title="Düsseldorf bekommt ein weiteres Hyatt"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.business-travel.de/duesseldorf-bekommt-ein-weiteres-hyatt/50206/"
              ></PressTile>
              <PressTile
                publisher="Neueröffnung Info"
                title="Hyatt Place"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.neueroeffnung.info/duesseldorf/hyatt-place"
              ></PressTile>
              <PressTile
                publisher="Tophotel"
                title="Hyatt Place Düsseldorf Mercedesstraße geplant"
                flag="🇩🇪"
                isPdf="f"
                link="https://www.tophotel.de/hyatt-und-euroatlantic-hyatt-place-duesseldorf-mercedesstrasse-geplant-114314/"
              ></PressTile>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script setup>
import PressTile from "../components/pressComponent2.vue";
import navbarHyatt from "../components/navbarHyatt.vue";
import Footer from "../components/Footer.vue";
import { onMounted } from "vue";
onMounted(() => {
  document.body.style.background = "#f5f5f7";
});
</script>

<style scoped>
.titleImg {
  position: fixed;
  width: 100vw;
  height: 70vh;
  object-fit: cover;
  object-position: center bottom;
  left: 0;
  z-index: -1;
}
.reviews {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.reviews > * {
  width: unset;
  flex-grow: 1;
}
.inline {
  width: 100%;
}
.press {
  max-width: 1000px;
  margin: 70vh auto 0 auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.press h1 {
  font-weight: 100;
  font-size: 1.7em;
  line-height: 1em;
  padding-top: 0.2em;
}
.title {
  text-align: left;
  position: absolute;
  transform: translateY(-4em);
  color: white;
  font-size: 1.5em;
}
.dayContainer {
  display: flex;
  padding-bottom: 2.5em;
  box-shadow: 0 -10px 30px -30px rgb(184, 184, 184);
}
.dayContainer > p {
  text-align: right;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  padding: 32px 0.5em 1em 0;
  margin: 0;
  height: 18px;
  z-index: 10;
}

.press {
  padding: 0 0 2em 2em;
}

.borderB {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.borderT {
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}
#review {
  transform: translateX(-1em);
}

@media only screen and (max-width: 600px) {
  .title h2 {
    padding-left: 16px;
  }
  #review {
    transform: translateX(0);
  }
  .dayContainer {
    flex-direction: column;
  }
  .dayContainer > p {
    padding-right: 1em;
    background: rgba(245, 245, 245, 0.508);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }

  .press {
    padding: 0 0 2em 0;
  }
  .press > h2 {
    padding-left: 32px;
  }
}
</style>
