<template>
  <div class="pressTile" @click="onClick(link)">
    <div class="sticky">
      <div class="head">
        <div class="top">
          <a>{{ publisher }}</a>
          <div class="info">
            <img
              v-if="isPdf == 't'"
              src="../assets/Logos/pdf.png"
              style="padding-right: 0.3em"
              alt=""
            />

            <div v-if="String(flag).includes('FS')" class="tooltip" @click="onClick('https://ando-studios.com/')">
              <a>{{
                flag
              }}</a>
              <a class="left">future-studios.com</a>
            </div>
            <a v-else>{{ flag }}</a>
            <!--

            <img v-else src="../assets/Logos/globe.png" alt="" />

            -->
          </div>
        </div>
        <h1 v-html="title"></h1>
      </div>
    </div>
  </div>
</template>
<script setup>
defineProps(["publisher", "title", "flag", "isPdf", "link"]);
const onClick = (link) => {
  window.open(link, "_blank").focus();
};
</script>
<style scoped>
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top a {
  font-weight: 600;
  font-size: 1.1em;
}

.top img {
  width: 1.5em;
  transform: translateY(3px);
}

.info a {
  font-size: 1.5em;
}
.sticky {
  position: sticky;
  background-color: inherit;
}
.press {
  max-width: 1000px;
  margin: 0 auto;
  background: white;
}
.press h1 {
  font-weight: 100;
  font-size: 1em;
  line-height: 1.5em;
  margin-top: 0em;
}
.dayContainer {
  display: flex;
  padding-bottom: 2.5em;
  box-shadow: 0 -10px 30px -30px rgb(184, 184, 184);
}
.dayContainer > p {
  text-align: right;
  flex-shrink: 0;
  position: sticky;
  top: 7em;
  padding: 32px 0.5em 1em 0;
  margin: 0;
  height: 18px;
  z-index: 10;
}

.pressTile {
  display: inline-block;
  transition: all 0.5s ease-in;
  padding-bottom: 0.5em;
  transform: translateY(1px);
  width: 100%;
  text-align: left;
}
.pressTile p {
  margin: 0;
}

.pressTile > div > p {
  margin: 0 0 0 1em;
}

.pressTile .head {
  top: 0;
  padding: 1em;
  padding-top: 0.7em;
  margin-right: 2em;
  transition: all 0.3s ease, border-radius 0s;
  box-shadow: 0px 8px 10px -10px rgb(190, 190, 190);
}

.pressTile .head:hover {
  box-shadow: inset 0 0 10px #a5a5a5;
  border-radius: 20px;
}

.pressTile .sticky {
  width: 100%;
  top: 0;
  margin-top: 1em;
  z-index: 10;
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: all 0s;
}
.ext {
  max-height: 500vh;
  overflow: auto;
  transition: all 0.7s ease-in;
}

button {
  background: white;
  color: black;
  border-radius: 1em;
  padding: 0.25em 0.8em;
  border-width: 1px;
  transition: all 0.2s ease-in;
  font-size: 0.9em;
}
button:hover {
  background: black;
  color: white;
}
.press {
  padding: 2em 0 2em 2em;
}

.borderB {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.borderT {
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.content p {
  margin: 1em 0;
  padding-right: 2em;
}

@media only screen and (max-width: 600px) {
  .pressTile {
    padding-left: 0;
  }
  .pressTile .head {
    top: 0;
    margin: 0;
  }
  .press {
    padding: 2em 0 2em 0;
  }
  .press > h2 {
    padding-left: 32px;
  }
  .content > div {
    padding-left: 0 !important;
  }
}
.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
  z-index: 999;
  pointer-events: all;
}

.tooltip a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  margin: 12px 0;
  font-weight: 100;
}

.tooltip .left {
  top: 120%;
  right: 0;
  transform: translate(0, -50%);
  color: #ffffff;
  background-color: #000000;
  font-weight: normal;
  font-size: 1em;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
  padding: 0.5em;
  width: max-content;
}

.tooltip:hover .left {
  display: block;
}

.tooltip .left i {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -12px;
  width: 3em;
  height: 24px;
  overflow: hidden;
}
</style>
