<template>
  <div class="placeholder">
    <div class="wrap">
      <div class="footer">
        <div>
          <a
            href="https://euroatlantic.ag"
            target="_blank"
            style="font-size: 16.9px"
            ><img src="../assets/Logos/EA.svg" alt="EuroAtlantic Logo" />© 2023
            EuroAtlantic LLC</a
          >
        </div>
        <div class="right">
          <a href="/legal">Impressum & Datenschutz</a>
          <a href="/terms">Allgemeine Geschäftsbedingungen</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.placeholder {
  height: 12em;
}
.wrap {
  z-index: 0;
  background: black;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 3.5vw;
  height: 12em;
}

.footer {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer a {
  pointer-events: all;
  color: white;
  text-decoration: none;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.footer > * {
  padding-bottom: 2em;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.right {
  text-align: right;
}
img {
  max-width: 100%;
}

.right a{
  text-align: right;
  font-size: .8em;
}
</style>
