import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import Ver2 from "../views/Version2.vue";
import Presse from "../views/Presse.vue";
import Legal from "../views/Legal.vue";
import Terms from "../views/Terms.vue";
import Login from "../views/Login.vue";

import storageHelper from "storage-helper";

const routes = [
  {
    path: "/",
    name: "AndoStudios",
    component: Ver2,
    meta: {
      publicRoute: true,
      title: "Ando Campus",
      metaTags: [{ name: "theme-color", content: "#319197" }],
      requiresAuth: true,
    },
  },
  {
    path: "/press",
    name: "Ando Presse",
    component: Presse,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/legal",
    name: "Impressum & Datenschutz",
    component: Legal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/terms",
    name: "Ando Campus",
    component: Terms,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Ando Campus",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!storageHelper.getItem("user-password")) next("/login");
    else if(storageHelper.getItem('user-password') === process.env.VUE_APP_PW) next();
  } else next();
});

trackRouter(router);

export default router;
