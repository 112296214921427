<template>
  <div class="blur" :class="{ open: isOpen }">
    <div
      class="menuList"
      :class="{ open: isOpen, isPress: isPress }"
      id="menuList"
    >
      <div
        style="display: grid; padding-top: 8em"
        :class="{ onPress: isPress }"
      >
        <p>
          <a class="underlineHover" @click="scrollTo('values')">Zielsetzung</a>
        </p>
        <p>
          <a class="underlineHover" @click="scrollTo('buildings')"
            >Gebäudeabschnitte</a
          >
        </p>
        <p>
          <a class="underlineHover" @click="scrollTo('facts')"
            >Fakten & Highlights
          </a>
        </p>
        <p>
          <a class="underlineHover" @click="scrollTo('levels')">Grundrisse</a>
        </p>
        <p>
          <a class="underlineHover" @click="scrollTo('end')">Zwischennutzung</a>
        </p>
      </div>

      <div style="display: grid; padding-bottom: 1em">
        <p>
          <a
            href="https://www.instagram.com/andofuturestudios/"
            target="_blank"
            class="underlineHover"
            >Instagram</a
          >
        </p>
        <p>
          <a
            href="https://www.linkedin.com/company/tadao-ando-campus-and-tower/"
            target="_blank"
            class="underlineHover"
          >
            LinkedIn
          </a>
        </p>
        <p>
          <a class="underlineHover" href="/press">Presse</a>
        </p>
        <p>
          <a class="underlineHover" @click="scrollTo('contact')">Kontakt</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["onClose"]);
const props = defineProps({
  isOpen: Boolean,
  isPress: Boolean,
});
const scrollTo = (pos) => {
  emit("onClose");
  let h = document.getElementById(pos).getBoundingClientRect().top;
  let w = window.pageYOffset;
  let v = h === undefined ? 0 : h + w;
  window.scrollTo({ behavior: "smooth", top: v });
};
</script>

<style scoped>
.onPress > * {
  display: none;
}
.menuList {
  min-width: 230px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0 0;
  justify-content: space-between;
  z-index: 1;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.5, 0, 0, 0.75);
  pointer-events: all;
  z-index: 99;
}
.menuList.isPress {
  justify-content: flex-start;
}

.menuList.open {
  transform: translateX(0);
  opacity: 1;
}

.menuList a {
  pointer-events: all;
  text-decoration: none;
  color: black;
}
.menuList p {
  text-align: right;
  font-size: 1em;
  margin: 0.25em 0;
  padding: 0.3em 2em;
  cursor: pointer;
}

.underlineHover {
  display: inline-block;
  padding-bottom: 0.1rem; /* defines the space between text and underline */
  position: relative;
}
.underlineHover::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1.2px;
  background: black;
  transition: width 0.25s ease-out;
}
.underlineHover:hover::before {
  width: 100%;
}
.blur {
  justify-content: flex-end;
  display: flex;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in;
  pointer-events: none;
}
.blur.open {
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
</style>
