<template>
  <Script
    type="text/javascript"
    charset="UTF-8"
    src="//cdn.cookie-script.com/s/68243020cf60adb07b242dd0d8b1b7d3.js"
  ></Script>
  <router-view />
</template>

<script>
import navbar from "@/components/navbarHyatt";
import { useHead } from "@vueuse/head";
export default {
  name: "App",
  components: {
    navbar,
  },
  setup() {
    useHead({
      title: "Ando Campus",
      meta: [
        {
          name: `description`,
          content:
            "Willkommen im neuen Campus-Turm von Tadao Ando. Diese moderne Anlage verfügt über eine einzigartige Kombination aus natürlichen Materialien und beeindruckendem Design. Es ist der ideale Ort zum Lernen, Arbeiten und Wachsen. Bis bald auf dem Campus!",
        },
        {
          name: "theme-color",
          content: "#000000",
        },
      ],
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
}

@font-face {
  font-family: "Lemon";
  src: url(./assets/fonts/LEMONMILK-Regular.otf);
}

body {
  margin: 0;
  background: black;
  padding: 0;
}

html {
  width: 100vw;
  padding: 0;
  margin: 0;
}

.navbar {
  overflow: hidden;
}
</style>
