import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag-next";
import { createHead } from "@vueuse/head";

const app = createApp(App);
const head = createHead();

app.use(VueGtag, {
  property: {
    id: "UA-209106581-1",
  },
});

app.use(head);

app.use(router).mount("#app");
