<template>
  <div class="wrap">
    <div id="iFrame">
      <iframe
        src="https://player.vimeo.com/video/810555828?dnt=1&autoplay=1&loop=1&autopause=1&background=1"
        frameborder="0"
        allow="autoplay; fullscreen;"
        allowfullscreen
      ></iframe>
    </div>
    <iframe
      id="player2"
      src="https://player.vimeo.com/video/626588068?dnt=1&autoplay=0&loop=0&autopause=1&playsinline=0"
      frameborder="0"
      allow="autoplay; fullscreen;"
      allowfullscreen
    ></iframe>
    <div class="cta">
      <h2 style="margin-bottom: -0.1em">TADAO ANDO</h2>
      <h3>CAMPUS & TOWER</h3>
      <div style="width: fit-content">
        <div class="btn" @click="play()">
          <div class="btn-inner">
            <a style="font-size: 1em">▸</a> Video Ansehen
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Vimeo from "@vimeo/player";

const play = () => {
  let fullscreenChange = null;
  const wrapper = document.getElementById("player2");

  const player = new Vimeo(wrapper);
  player.setVolume(1);
  player.play();
  if (
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  ) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  } else {
    if (wrapper.requestFullscreen) {
      wrapper.requestFullscreen();
    } else if (wrapper.mozRequestFullScreen) {
      wrapper.mozRequestFullScreen();
    } else if (wrapper.webkitRequestFullscreen) {
      wrapper.webkitRequestFullscreen();
    } else if (wrapper.msRequestFullscreen) {
      wrapper.msRequestFullscreen();
    }
  }
  document.getElementById("iFrame").remove();
};
</script>
<style scoped>
.wrap {
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
#iFrame,
#iFrame > iframe,
.wrap > iframe {
  width: 100vw;
  height: 100vh;
  background: black;
}
#iFrame > iframe {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

img {
  width: 100vw;
  left: 0;
  top: 0;
  z-index: -1;
}
.cta {
  position: absolute;
  display: flex;
  align-items: left;
  flex-direction: column;
  color: white;
  left: 7vw;
  top: 60vh;
  text-align: left;
  font-size: calc(1.2vw + 1em);
}

.cta>h3{
  font-size: 1.01em;
}

h2,
h3 {
  margin: 0;
}
h2 {
  font-size: 1.5em;
  font-weight: 900;
}
.btn {
  margin-top: 0.8em;
  padding: 10px 18px;
  color: white;
  text-decoration: none;
  position: relative;
  background: transparent;
  border: solid white 2px;
  border-radius: 10px;
  text-align: left;
  text-indent: 2px;
  transition: color 0.1s linear 0.05s;
  font-size: 0.7em;
}
.btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  border-radius: 7px;
  background: white;
  z-index: 1;
  opacity: 0;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0.2s;
}
.btn::after {
  transition: border 0.1s linear 0.05s;
}
.btn .btn-inner {
  position: relative;
  z-index: 2;
}
.btn:hover {
  color: black;
  transition: color 0.1s linear 0s;
}
.btn:hover::before {
  top: 0;
  height: 100%;
  opacity: 1;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0s;
}
.btn:hover::after {
  border-color: black;
  transition: border 0.1s linear 0s;
}
#player2 {
  width: 100vw;
}


@media (max-aspect-ratio: 16/9) {
  #iFrame > iframe {
    width: calc(100vh * 1.77777777778);
  }
}
@media (max-aspect-ratio: 12/9) {
  .cta {
    top: 75vh;
    top: 75svh;
  }
}
</style>
