<template>
  <div class="aboveAll">
    <div id="title">
      <div class="titleImg">
        <img
          src="../assets/Renderings/soi.jpg"
          style="object-position: 45% 50%"
        />
      </div>
      <div class="titleTxt">
        <a class="appear" style="width: 100%">Campus & Tower</a>
        <h1 class="appear" style="margin: 0">
          Der kreative hightech<br />Campus der Zukunft
        </h1>
        <button class="titleArrow">
          <div class="arrow">
            <img src="../assets/arrow.svg" alt="" />
          </div>
        </button>
      </div>
    </div>
    <div id="inquiry">
      <a class="appear"
        >Bereit Ihr Unternehmen<br />in die Zukunft zu führen?</a
      >
      <div
        class="btn"
        @click="openLink('mailto:schwitzke@euroatlantic.ag', false)"
      >
        <div class="btn-inner">Anfrage senden</div>
      </div>
    </div>
    <div id="intro">
      <a class="appear"
        >Der bedeutendste Architekt des 20. Jahr&shy;hunderts krönt mit einem
        Architektur&shy;denkmal für Düssel&shy;dorf sein Lebens&shy;werk. Tadao
        Ando und Euro&shy;Atlantic schaffen ein künstler&shy;isches
        Gesamt&shy;kunstwerk für die japan&shy;ische, asi&shy;atische
        Kunst&shy;hauptstadt Europas. Als Herz&shy;stück des Campus entsteht
        hier ein Passiv&shy;hochhaus für moderne Arbeits&shy;welten mit
        inte&shy;grierter Grünp&shy;lanung und einer Kunst&shy;kathedrale in 100
        Metern Höhe. Der erste Euro&shy;päische KI gestützte Euro&shy;Atlantic
        Cyber Campus wurde als Inkubator für digitale
        High&shy;tech&shy;unternehmen und Startups entwickelt. Eng verwoben mit
        der Metropolen&shy;architektur des renom&shy;mierten Pritzker
        Preis&shy;trägers Tadao Ando, der schon zu Lebzeiten eine
        Architektur&shy;legende ist, wird hier unsere Zukunft mitgestaltet.</a
      >
    </div>
    <div>
      <Diashow
        :imgUrls="exterior"
        :txts="exteriorTxts"
        style="width: 76vw; margin: 0 0 0 12vw"
      ></Diashow>
    </div>
    <section id="values" class="line">
      <h2 class="appear">
        UNSERE WERTE &<br />
        ZIELSETZUNG
      </h2>
      <div class="flex">
        <div class="txt">
          <a
            >„Mein Bau für Düssel&shy;dorf soll die Natur nicht spiegeln, es
            soll zeigen, dass der Mensch in der Stadt mit der Natur leben kann.
            Mein Bau soll eine grüne Oase werden, in dem die Menschen zur Ruhe
            kommen. Meine Archi&shy;tektur soll ber&shy;uhigen“<br /><br />sagt
            Tadao Ando über das Gebäude.<br /> </a
          >
        </div>
        <img class="appear" src="../assets/Renderings/Rendering2.jpg" />
      </div>
    </section>
    <section class="look" @scroll="lookOnScroll">
      <span
        ><b>Holz</b> <img src="../assets/Renderings/pictoH1.png" alt=""
      /></span>
      <span style="margin: 0 2em">
        <b>Holzhybrid</b><img src="../assets/Renderings/pictoH2.png" alt="" />
      </span>
      <span
        ><b>Beton</b><img src="../assets/Renderings/pictoH3.png" alt="" />
      </span>
    </section>
    <section id="buildings" class="line">
      <h2 class="appear">
        Tadao Ando<br />
        Campus & Tower
      </h2>
      <div class="flex">
        <div class="txt">
          <a>
            Wir freuen uns Ihnen einen Ein&shy;blick in unser Pro&shy;jekt geben
            zu können und zeigen Ihnen die Vor&shy;züge und noch zur
            Ver&shy;fügung stehenden Flächen der Drei Gebäudeteile:<br /> </a
          >
          <div ref="txtBuilding">
            <span>
              Dieser Baustein des Tadao Ando Campus & Tower umfasst circa 12.000
              Quadrat&shy;meter und bildet eine Viel&shy;zahl von Büro,
              Kon&shy;ferenz- und Gastro&shy;nomie&shy;flächen ab. Die
              aus&shy;drucks&shy;starke Fassade sowie der beg&shy;rünte
              Innen&shy;hof und die groß&shy;zügige Geschoss&shy;igkeit
              ver&shy;leihen dem Gebäude einen eigenen Charakter und eine
              unverwechselbare Qualität.</span
            ><span
              >Das Herzstück des Pro&shy;jektes ist der 105 Meter hohe Hotel,
              Gastro&shy;nomie- und Büro&shy;turm. Auf circa 35.000
              Quadrat&shy;metern entsteht eine Symbiose aus groß&shy;zügigen
              Beher&shy;bergungs&shy;flächen und Arbeits&shy;welten. Die
              Obersten Etagen sowie die Dach&shy;terrasse stehen der
              Öffentlich&shy;keit in Form eines Restau&shy;rants und
              Veranstaltungs&shy;fläche zur Verfügung.</span
            ><span
              >In einem zweiten Planungs&shy;schritt realisiert die
              Euro&shy;Atlantic den Schluss&shy;stein des Tadao Ando Campus &
              Tower. Auf circa 60.000 Quadrat&shy;metern werden Kita, Schulen,
              Einzelhandel, Kunstgalerien und Büro&shy;flächen entstehen. Die
              begeh&shy;bare und stark begrünte Dach&shy;fläche macht den Campus
              zu einem unvergleich&shy;baren Aufenthalt&shy;sort, von dem aus
              Sie Düssel&shy;dorf neu erleben können.</span
            >
          </div>
          <div class="bubbles" ref="btnBuildings">
            <div class="bubble isActive" @click="currentBuilding = 0">
              Sockel
            </div>
            <div class="bubble" @click="currentBuilding = 1">Tower</div>
            <div class="bubble" @click="currentBuilding = 2">Campus</div>
          </div>
        </div>
        <div class="imgs appear" ref="buildings">
          <img src="../assets/Renderings/iso0.png" alt="" />
          <img
            src="../assets/Renderings/iso2.png"
            style="opacity: 0.5"
            alt=""
          />
          <img src="../assets/Renderings/iso1.png" alt="" />
          <img src="../assets/Renderings/iso3.png" alt="" />
        </div>
      </div>
    </section>
    <section id="facts" class="line">
      <h2 class="appear">
        Hightlights &<br />
        Facts
      </h2>
      <div class="flex">
        <div>
          <h2 class="title">CAMPUSFLÄCHE</h2>
          <h1>100.000</h1>
          <a>QUADRATMETER</a>
        </div>
        <div>
          <h2 class="title">LICHTE RAUMHÖHE</h2>
          <h1>3 - 5</h1>
          <a>METER</a>
        </div>
        <div style="flex-basis: 400px">
          <h2 class="title">HÖHE TOWER</h2>
          <img
            src="../assets/Renderings/schnitt2.png"
            style="
              filter: brightness(0.1) invert(1);
              object-fit: contain;
              max-width: unset;
            "
            alt=""
          />
        </div>
        <div>
          <h2 class="title">FOOD</h2>
          <h3>
            3
            <img
              style="
                display: inline;
                width: 0.5em;
                transform: translate(-30%, -10%);
                filter: invert(1);
              "
              src="../assets/Renderings/iconFood.svg"
            />
          </h3>
          <a>Restaurants</a>
        </div>
        <div>
          <h2 class="title">INTELLIGENTE<br />GEBÄUDESTEUERUNG</h2>
          <img
            src="../assets/Renderings/iconAI.svg"
            alt=""
            style="width: 150px; filter: invert(1)"
          />
        </div>
        <div>
          <h2 class="title">IM QUARTIER</h2>
          <h4>Einzelhandel<br />& Markthalle</h4>
        </div>
        <div>
          <h2 class="title">EBENEN<br />/STOCKWERKE</h2>
          <h3>26</h3>
        </div>
        <div>
          <h2 class="title">HAUPTBAHNHOF</h2>
          <h3>8</h3>
          <a>Minuten</a>
        </div>
        <div>
          <h2 class="title">FLUGHAFEN</h2>
          <h3>6</h3>
          <a>Minuten</a>
        </div>
        <div style="flex-basis: 450px">
          <h2 class="title">ÖFFENTLICHE VERKEHRSMITTEL</h2>
          <img
            src="../assets/Renderings/distances.png"
            style="max-width: 450px"
            alt=""
          />
        </div>
      </div>
    </section>
    <section class="look" @scroll="lookOnScroll" style="background: #eaeaea">
      <span
        ><b>Erschließung</b> <img src="../assets/Renderings/picto1.png" alt=""
      /></span>
      <span style="margin: 0 2em">
        <b>Gastronomie</b><img src="../assets/Renderings/picto2.png" alt="" />
      </span>
      <span
        ><b>KiTa und Bildung</b
        ><img src="../assets/Renderings/picto3.png" alt="" />
      </span>
    </section>
    <section id="levels">
      <div id="pin" style="z-index: 1">
        <h2 class="line appear" id="lvlTitle">
          Grundrisse<br />
          der Ebenen
        </h2>
        <div id="lvlBg">
          <img src="../assets/Renderings/schnitt3.png" alt="" />
          <svg viewBox="0 0 2537 1839" preserveAspectRatio="xMinYMid slice">
            <rect id="lvl23" width="1041" height="61" x="184" y="208" />
            <rect id="lvl7" width="2352" height="66" x="184" y="1291" />
            <rect id="lvl5" width="2536" height="65" x="0" y="1417" />
            <rect id="lvl3" width="2536" height="65" x="0" y="1606" />
            <rect id="lvl0" width="2536" height="86" x="0" y="1747" />
          </svg>
        </div>
      </div>
      <div class="txt">
        <div class="appear">
          <h1 class="first">23. OG</h1>
          Als Herzstück des „Arts Club“ Konzeptes befindet sich in den beiden
          obersten Etagen des Hochhauses ein atemberaubendes Restaurant. Umgeben
          von Kunst und mit einem unvergleichlichen Ausblick über Düsseldorf
          können Sie dort ein unvergessliches kulinarisches Erlebnis genießen.
          <img src="../assets/Renderings/lvl27.png" alt="" />
          <div class="btn" @click="openLvl(23)">
            <div class="btn-inner">Grundriss Ansehen</div>
          </div>
        </div>
        <div class="appear">
          <h1>7. OG</h1>
          Ab dem Siebten Obergeschoss ist es den Büronutzern möglich die
          weitreichenden Dachgärten auf den tieferliegenden Gebäudeteilen zu
          betreten. Die Dachgärten wurden von dem Schweizer
          Landschaftsarchitekten Enzo Enea gestaltet und bieten neben einer
          unvergleichlichen Aufenthaltsqualität weitreichende Blicke Richtung
          Düsseldorf und Umgebung.
          <img src="../assets/Renderings/lvl7.png" alt="" />
          <div class="btn" @click="openLvl(7)">
            <div class="btn-inner">Grundriss Ansehen</div>
          </div>
        </div>
        <div class="appear">
          <h1>5. OG</h1>
          Die innovativen und zukunftsweisenden Büroflächen des Tadao Ando
          Campus & Tower befinden sich unter anderem im fünften Obergeschoss des
          Westlichen und Östlichen Gebäudes. Das Hyatt Hotel befindet sich
          ebenfalls im fünften Oberschoss und bietet mit dem Hyatt Place und
          House Konzept ein vielseitiges Beherbergungsangebot für Geschäfts- und
          Privatreisende.
          <img src="../assets/Renderings/lvl5.png" alt="" />
          <div class="btn" @click="openLvl(5)">
            <div class="btn-inner">Grundriss Ansehen</div>
          </div>
        </div>
        <div class="appear">
          <h1>3. OG</h1>
          Im Zweiten und Dritten Obergeschoss überzeugt das Westliche Gebäude
          mit großflächigen Konferenzflächen. Die Konferenzflächen umfassen
          circa 5.000 Quadratmeter und werden öffentlich buchbar sein. Im
          Hochhaus befindet sich auf diesen Etagen ein Fitness- und
          Wellnessanbieter. Im Zweite und Dritte Obergeschoss des östlich
          liegenden Baukörpers befinden sich Büroflächen sowie kleine und
          mittelgroße Meetingräume für alle Mieter.
          <img src="../assets/Renderings/lvl3.png" alt="" />
          <div class="btn" @click="openLvl(3)">
            <div class="btn-inner">Grundriss Ansehen</div>
          </div>
        </div>
        <div>
          <h1>EG</h1>
          Unser Erdgeschoss bildet ausschließliche öffentliche Nutzungen ab.
          Beginnend am Westlichen Gebäude finden Sie eine großzügige Lobby für
          die darüberliegenden Büroflächen sowie ein Angebot zweier Gastronomen.
          Der öffentliche und verkehrsberuhigte Stadtplatz West ist das
          Drehkreuz des Campus und ermöglicht den Zutritt zu der Hotellobby im
          Hochhaus sowie den mit Kunstwerken versehenen Garten inmitten der
          beiden Östlichen Gebäuderiegel. Der Garten schließt an eine circa
          2.000 Quadratmeter große Markthalle, Supermarkt und
          Einzelhandelsflächen an. Neben einigen Cafés befinden sich der Eingang
          zu den Büroflächen sowie die Eingänge zur Kita, Bildungsinstitut und
          Kunstgalerie.
          <img src="../assets/Renderings/lvl0.png" alt="" />
          <div class="btn" @click="openLvl(0)">
            <div class="btn-inner">Grundriss Ansehen</div>
          </div>
        </div>
      </div>
    </section>
    <section id="end">
      <div class="line">
        <a>ZWISCHENNUTZUNG</a><br />
        <h2>Ando Future Studios</h2>
        <img src="../assets/Renderings/Bild11.jpg" style="object-fit: cover" />
        <div class="btn" @click="openLink('https://ando-studios.com', true)">
          <div class="btn-inner">Weitere Informationen</div>
        </div>
      </div>
      <div class="line" id="contact">
        <a>KONTAKT</a><br />
        <h2>Sprechen Sie uns an</h2>
        <img src="../assets/Renderings/Bild12.jpg" />
        <div style="transform: translateY(-2.8em); position: absolute">
          Projektleitung: David Schwitzke & Matthias Lehner
        </div>
        <div
          class="btn"
          @click="openLink('mailto:schwitzke@euroatlantic.ag', false)"
        >
          <div class="btn-inner">Kontakt anfragen</div>
        </div>
      </div>
    </section>
  </div>
  <Footer></Footer>
</template>

<script setup>
import { onMounted, ref, watch } from "@vue/runtime-core";
import gsap from "gsap";
import scrollTrigger from "gsap/ScrollTrigger.js";
import Diashow from "../components/Diashow.vue";
import Footer from "../components/Footer.vue";

const openLvl = (a) => {
  window.open("/level" + a + ".pdf", "_blank").focus();
};
const openLink = (a, newTab) => {
  window.open(a, "_blank").focus();
};

gsap.registerPlugin(scrollTrigger);
document.body.style.overflowY = "unset";
document.body.style.background = "white";
const getImgUrl = (src) => {
  var images = require.context("../assets/Renderings", false, /\.jpg$/);
  return images("./" + src);
};

const exterior = [
  "Rendering1.jpg",
  "Bild03.jpg",
  "Bild04.jpg",
  "Bild05.jpg",
  "Rendering3.jpg",
];

const exteriorTxts = [
  "<b>High-Tech Village:</b><br>Mitten in der Stadt entsteht ein Kreativ&shy;ort der Tech&shy;nologie als „Incubator“ und „Accel&shy;erator“ einer welt&shy;weit ver&shy;netzten Wissens&shy;gesellschaft.",
  "<b>Architektonisches Gesamtkunstwerk für Düsseldorf:</b><br>Am Stadteingang Nord gelegen ist der Tadao Ando Campus & Tower prägend für den ersten Eindruck von Düsseldorf.",
  "<b>Vielseitig und für alle:</b><br>Der Tadao Ando Campus und Tower bietet mit seinem vielseitigen Nutzungsmix eine Destination, die standortbildend ist.",
  "<b>Arbeitswelten und Natur:</b><br>Rund 40.000 m2 nach DGNB-Vorgaben errichtete Büro- und Konferenzflächen setzen die Standards für die Arbeits-, Lebens- und Wohlfühlwelt von Morgen und Übermorgen: eine harmonische Vereinigung von Weltklassearchitektur und trendsetzender Funktionalität - eine Symbiose von High- Tech und Natur.",
  "<b>Der Urbane Wald:</b><br>In einzigartiger Zusammenarbeit mit dem Weltstar der Landschaftsarchitekten Enzo Enea entsteht ein Urbaner Wald mit über 300 Bäumen. Somit leistet das Projekt einen belegbaren Beitrag zur CO2 Speicherung und zur Verbesserung des Mikroklimas in Düsseldorf.",
];
const lookOnScroll = (e) => {
  //console.log(e.target.scrollLeft)
};
const currentBuilding = ref(0);
const buildings = ref(null);
const btnBuildings = ref(null);
const txtBuilding = ref(null);
watch(currentBuilding, (c, p) => {
  console.log(p, c);
  btnBuildings.value.children[p].classList.toggle("isActive");
  btnBuildings.value.children[c].classList.toggle("isActive");
  buildings.value.children[p + 1].style.opacity = 0;
  buildings.value.children[c + 1].style.opacity = 0.5;
  txtBuilding.value.style.transform = "translateX(-" + c + "00%)";
});
const schnitt = ref(null);
const bg = ref(null);
const currentLvl = ref(-1);
const lvl = ref(null);
const loadContent = (i) => {
  if (i == currentLvl.value) i -= 1;
  currentLvl.value = i;
  if (currentLvl.value < 0) bg.value.opacity = 0;
  else bg.value.src = getImgUrl(exterior[currentLvl.value]);
  txtLvl.value = txtLvls[currentLvl.value];
  lvl.value.style.height = lvlHeights[currentLvl.value];
  console.log("loading img", currentLvl.value);
};
const isMobile = ref();
window.addEventListener(
  "resize",
  () => {
    scrollTrigger.refresh();
  },
  { passive: true }
);
onMounted(() => {
  const minWidth = (window.innerWidth * 0.6) / (2 * window.innerHeight * 2.865);
  const lvlTitleHeight = document.getElementById("lvlTitle").clientHeight;
  const lvls = document.getElementById("lvlBg").children[1].children;
  const slctLvl = (i, isActive) => {
    if (isActive) {
      lvls[i].style.opacity = 1;
      //if (i > 0) lvls[i - 1].style.opacity = 0;
      //if (i < 4) lvls[i + 1].style.opacity = 0;
    } else lvls[i].style.opacity = 0;
  };
  gsap
    .timeline({
      scrollTrigger: {
        trigger: "#pin",
        pin: true,
        pinSpacing: false,
        scrub: 0.3,
        end: () => {
          return (
            document.getElementById("levels").clientHeight -
            window.outerHeight +
            "px"
          );
        },
      },
    })
    .to("#lvlBg", { y: "-50vh", ease: "Power1.easeOut", duration: 5 }, 0);
  let lvlTitles = gsap.utils.toArray("#levels > .txt > *");
  lvlTitles.forEach((e, i) => {
    gsap.to(e, {
      scrollTrigger: {
        trigger: e,
        //start: "top " + lvlTitleHeight,
        start: "top center",
        end: e.clientHeight * 0.4 + "px",
        onToggle: (self) => slctLvl(i, self.isActive),
      },
    });
  });
  gsap.utils.toArray(".appear").forEach((e) => {
    gsap.from(e, {
      scrollTrigger: {
        trigger: e,
        start: "top 85%",
        toggleActions: "play none none reverse",
      },
      opacity: 0,
      y: 50,
      duration: 1,
      ease: "Power2.easeOut",
    });
  });
});
</script>

<style scoped>
.aboveAll > * {
  background: white;
  z-index: 1;
  position: relative;
}
h4 {
  font-size: 2.2em;
  margin: 0.5em 0;
  line-height: 1em;
}
#title {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
#title .titleImg {
  flex: 1 1 0;
  min-width: 400px;
}
#title .titleTxt {
  flex: 1 1 0;
  min-width: 400px;
  color: white;
  background: black;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1em;
  font-size: clamp(1.5em, 3.1vw, 2em);
  text-align: left;
  flex-direction: column;
  padding-top: 15%;
  box-sizing: border-box;
}
#title .titleTxt a {
  font-size: 0.7em;
}
img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}
#inquiry {
  padding: 2em;
  color: white;
  background: #4190ff;
  background: linear-gradient(55deg, rgb(105 167 255) 0%, rgb(64 144 255) 70%);
  background: #5cb8ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
#inquiry a {
  font-weight: 100;
  font-size: 1.3em;
}
#inquiry .btn {
  color: white;
  border-color: white;
  background: unset !important;
}
#inquiry .btn:hover {
  color: black;
}
.btn {
  font-weight: 500;
  background: white;
  width: max-content;
  padding: 0.5em 1em;
}
#intro {
  font-size: 1.1em;
  color: black;
  background: white;
  padding: 4em 12vw;
  text-align: justify;
}
.titleArrow {
  background: unset;
  border: unset;
  width: 60px;
  position: absolute;
  left: 75vw;
  top: 188vh;
  transform: translateX(-60%);
}
.titleArrow .arrow {
  animation: arw 2s 0s infinite;
}
.line {
  text-align: left;
  background: #eaeaea;
  padding: 3em 12vw 4em 12vw;
  position: relative;
}

section.line {
  padding-top: 8em;
}
.line h2 {
  font-size: 2em;
  margin-bottom: 1em;
  font-weight: 700;
  text-transform: uppercase;
}
.line::before {
  position: absolute;
  content: "";
  background: black;
  top: 8.5em;
  left: 12vw;
  right: 12vw;
  height: 3px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}
.flex .txt {
  text-align: justify;
  display: flex;
  flex: 1 0 240px;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.1em;
}
.flex img {
  flex: 99 1 400px;
  overflow: hidden;
}
#values .flex .txt .btn::before {
  background: black;
}
#values .flex .txt .btn:hover {
  color: white;
}
.look {
  display: flex;
  text-align: left;
  padding: 4em 2em;
  overflow: auto;
  align-items: baseline;
}
.look > * {
  flex: 1 0 280px;
  text-align: center;
}
#buildings .flex {
  gap: 0;
}
#buildings > a {
  text-align: justify;
  font-size: 1.1em;
}
#buildings .flex .txt {
  justify-content: start;
  position: relative;
  overflow: hidden;
  flex: 1 0 252px;
}
#buildings .flex .txt div {
  transition: all 0.7s ease-out;
}
#buildings .flex .imgs {
  flex: 99 2 400px;
  position: relative;
  min-height: 216px;
}
#buildings .flex .imgs img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  object-fit: contain;
  object-position: 50% 20%;
  max-height: 400px;
  filter: invert(45%) sepia(100%) saturate(643%) hue-rotate(181deg)
    brightness(91%) contrast(112%);
  opacity: 0;
  transition: all 0.3s ease-in;
  transition-delay: all 1s;
}
#buildings .flex .imgs :nth-child(1) {
  filter: unset;
  opacity: 1;
}
#buildings .flex .txt div {
  display: flex;
}
#buildings .flex .txt div span {
  width: 100%;
  flex-shrink: 0;
}
.bubbles {
  display: block;
  display: flex;
  margin-top: 1em;
  justify-content: center;
  border: 0.5px solid black;
  border-radius: 25px;
  width: max-content;
}
.bubble {
  padding: 0.3em 0.4em;
  border-radius: 25px;
  transition: all 0.2s ease-in;
}
.bubble.isActive,
.bubble:hover {
  background: rgb(0 141 255);
  color: white;
}
#facts {
  color: white;
  background: #5cb8ff;
}
#facts::before {
  background: white;
}
#facts h2 {
  margin-bottom: -1em;
}
#facts .flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
#facts .flex div {
  flex: 1 1 220px;
  font-family: "Karma", serif;
}
#facts .flex div h1 {
  line-height: 1.3em;
  height: 1em;
  margin: 0;
  font-size: 4.5em;
  font-weight: bold;
}
#facts .flex div h2 {
  margin: 3.5em 0 0 0;
  font-size: 1.3em;
  font-weight: bold;
  position: relative;
  line-height: 1em;
  height: 2em;
}
#facts .flex div h3 {
  margin: 0;
  font-size: 6em;
  line-height: 1em;
}
#facts .flex div a {
  line-height: 1em;
}
#facts .flex div img {
  max-width: 218px;
  height: auto;
}
#facts .flex div .title::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  height: 1px;
  top: -0.5em;
  background: white;
}

#levels {
  text-align: justify;
  text-align: left;
  position: relative;
  padding: 3em 12vw 4em 12vw;
  overflow: hidden;
  color: black;
}
#levels .btn {
  padding: 13px 15px;
}
#levels > div {
  width: 60%;
}
#levels .txt {
  padding-top: 2em;
}
#levels h2 {
  padding: 4.6em 0 0 0;
  font-size: 2em;
  margin: 0;
  margin-bottom: 1em;
  font-weight: 700;
  text-transform: uppercase;
  background: white;
  min-width: 208px;
}
#levels h2::before {
  right: 0;
  left: 0;
  top: 4.4em;
}
#levels h1 {
  margin: 0;
  padding: 4em 0 0.5em 0;
}
#levels h1.first {
  padding-top: 0em;
}
#levels .btn {
  margin: 0.5em 0 4em 0;
}
#levels .btn:hover {
  color: white;
}
#levels .btn::before {
  background: black;
}

#lvlBg > * {
  position: absolute;
  left: 105%;
  top: 3em;
  width: 45vw;
  height: 100vh;
  object-position: 0% 50%;
}
#lvlBg > svg {
  fill: rgb(0 168 255 / 51%);
}
#lvlBg > svg > * {
  transition: all 0.3s ease-out;
  opacity: 0;
}

#end {
  background: #eaeaea;
  gap: 4em;
  display: flex;
  padding: 3em 12vw;
  justify-content: center;
  flex-wrap: wrap;
}
#end > div {
  background: unset;
  flex: 1 0 300px;
  overflow: hidden;
  padding: 0;
  max-width: 373px;
}
#end div h2 {
  font-size: 1.5em;
  margin: 0;
  line-height: 1em;
  padding-top: 0.5em;
}
#end div img {
  height: 30vh;
  object-fit: contain;
  margin: 2em 0 3em 0;
  background: #0c151b;
  line-height: 1em;
}
#end > div::before {
  left: 0;
  right: 0;
  height: 2px;
  top: 1.6em;
}

#end div .btn::before {
  background: black;
}
#end div .btn:hover {
  color: white;
}

@keyframes arw {
  0% {
    transform: translateY(0) scaleX(1);
  }
  50% {
    transform: translateY(100%) scaleX(0.9);
  }
  100% {
    transform: translateY(0) scaleX(1);
  }
}
.btn {
  padding: 13px 20px;
  color: black;
  text-decoration: none;
  position: relative;
  background: transparent;
  border: solid black;
  text-align: center;
  text-indent: 2px;
  transition: color 0.1s linear 0.05s;
}
.btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: white;
  z-index: 1;
  opacity: 0;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0.2s;
}
.btn::after {
  transition: border 0.1s linear 0.05s;
}
.btn .btn-inner {
  position: relative;
  z-index: 2;
}
.btn:hover {
  color: black;
  transition: color 0.1s linear 0s;
}
.btn:hover::before {
  top: 0;
  height: 100%;
  opacity: 1;
  transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0s;
}
.btn:hover::after {
  border-color: black;
  transition: border 0.1s linear 0s;
}

@media only screen and (max-width: 800px) {
  .titleArrow {
    display: none;
  }
  #levels > .txt > div > img {
    visibility: hidden;
  }
  h2 {
    font-size: 1.8em !important;
  }
}
</style>
